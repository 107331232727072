<template>
    <div>
        <baseInfo :orderBean="orderBean" isEdit/>
        <orderFeature/>
        <purchaseSum/>
    </div>
</template>

<script>
import baseInfo from '../components/BaseInfo'
import purchaseSum from '../components/PurchaseSum'
import orderFeature from '../components/OrderFeature'

export default {
  components: {
    baseInfo, purchaseSum, orderFeature
  },
  computed: {
    orderBean () {
      return this.$store.state.adx.orderBean
    }
  }
}
</script>
